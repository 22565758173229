<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="类型">
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                v-decorator="[
                  'type',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
                placeholder=""
              >
                <a-select-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="状态">
              <a-radio-group
                v-decorator="[
                  'status',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                button-style="solid"
              >
                <a-radio
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="背景图">
              <FileUpload @uploaded="uploaded">
                <a-button type="primary" style="margin-right: 8px">
                  {{ path ? "已上传" : "上传" }}
                </a-button>
                <a v-if="path" :href="path" target="_blank">预览</a>
              </FileUpload>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="备注"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="['remark']"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button type="primary" html-type="submit" :loading="saveLoading"
              >保存</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { add } from "@/api/parameterization/book";
import FileUpload from "@/components/file-upload";
export default {
  name: "addParamBook",

  components: {
    FileUpload,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      saveLoading: false,
      path: "",
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("bim_calc_book_type");
    },
    statusList() {
      return this.findDataDict("status");
    },
  },

  methods: {
    uploaded(list) {
      if (list.length > 0) {
        this.path = list[0].completePath;
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;

          add({
            ...values,
            modelImg: this.path,
          })
            .then(() => {
              this.$close(this.$route.path);
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
